.animated-text {
  position: absolute;
  @include rfs(left, $menuItemDistanceH);
  @include rfs(bottom, $menuItemDistanceV);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media (orientation: portrait) {
    left: 25vw;
    bottom: rvh(210);
  }

  & > * {
    font-family: aptly, sans-serif;
    @include rfs(font-size, 76); // Nessun "px" incluso
    line-height: 1;
    font-weight: bold;
  }

  .static {
    color: $color-accent;
    z-index: 1;
  }

  .caret {
    display: inline-block;
    width: 2px;
    background-color: $color-primary;
    color: transparent;
    margin-left: 2px;
    animation: blink $page-animation-speed steps(1) infinite;
  }
}
