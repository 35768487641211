.salotto-two-cols-section {
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  display: flex;
  justify-content: center;
  box-sizing: border-box;

  .row {
    @media (orientation: portrait) {
      flex-direction: column-reverse;
    }
  }

  span {
    font-size: inherit;
    line-height: inherit;
    &:not(.t-medium) {
      font-weight: inherit;
    }
  }
  .t-50 {
    .t-42 {
      font-size: inherit;
      line-height: inherit;
    }
  }
  .swiper {
    margin-right: 0;
    @include rfs(max-width, 600);
    @media (orientation: portrait) {
      max-width: 100%;
    }
    .swiper-wrapper {
      width: 100%;
    }
    .swiper-slide {
      height: rvh(800);
      @media (orientation: portrait) {
        height: 50vh;
      }
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
  }
}

// custom
#stay-tuned {
  .row {
    @media (orientation: portrait) {
      flex-direction: row-reverse;
    }
  }
  .swiper {
    margin: 0 auto;
    .swiper-slide {
      align-items: center;
      justify-content: center;
      display: flex;
      @media (orientation: portrait) {
        height: auto;
      }
      img,
      svg {
        align-self: center;
        @include rfs(height, 200);
        width: auto;
        object-fit: contain;
        @media (orientation: portrait) {
          height: rvh(150);
        }
      }
      svg {
        path {
          animation: strokeFill 2s linear infinite alternate-reverse;
        }
      }
    }
  }
  h2,
  .salotto-two-cols-section__subtitle {
    @extend .t-72;
  }
  h2 {
    @extend .stroke-white;
  }
  .salotto-two-cols-section__subtitle {
    -webkit-text-stroke: 0px transparent;
    color: $white;
  }
}
