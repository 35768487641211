.salotto-section {
  display: flex;
  align-items: center;
  justify-content: center;
  .row {
    gap: 0;
    justify-content: center;
  }
  .col-md-0-5 {
    width: 8%;
    @media screen and (orientation: portrait) {
      width: 100%;
    }
  }

  .col-md-5-5 {
    width: 42%; // Mantieni senza modifiche
    padding: rvh(60) rvw(30);
    padding-bottom: 0;
    @media screen and (orientation: portrait) {
      border: 2px solid $color-primary;
      width: 100%;
    }
    .swiper {
      // overflow: visible;
      .swiper-wrapper {
        padding-bottom: rvh(60);
        box-sizing: border-box;
      }
      .swiper-pagination-navigation {
        bottom: 15px;
      }
    }
  }
  .col-md-6 {
    width: 50%;
    @media screen and (orientation: portrait) {
      width: 100%;
    }
  }

  .content-wrap {
    * {
      font-size: inherit;
      line-height: inherit;
    }
  }

  img {
    width: 100%; // Mantieni senza modifiche
    @include rfs(max-width, 698); // Convertito in px
    height: auto;
    object-fit: cover;
    // aspect-ratio: 1; // Commentato
    @media screen and (orientation: portrait) {
      max-width: 100%;
    }
  }

  .overlay-content-wrap + .image-wrap {
    position: relative;
    height: 100%;

    > img {
      aspect-ratio: 1;
      height: 100%;
    }

    > img.base-content-image {
      z-index: 0;
    }

    > img.content-image {
      position: absolute;
      @include rfs(top, 0); // Convertito top in px
      @include rfs(left, 0); // Convertito left in px
      height: 100%; // Mantieni senza modifiche
      width: 100%; // Mantieni senza modifiche
      z-index: 1;
      opacity: 0;
      transition: opacity $fade-animation-speed ease-in-out;

      &.fade-in {
        opacity: 1;
      }
    }
  }

  .row {
    > div {
      box-sizing: border-box;
      text-align: left;

      @media screen and (orientation: landscape) {
        border: 2px solid $color-primary;
      }
      &:first-child {
        @media screen and (orientation: portrait) {
          border-top: 2px solid $color-primary;
          border-left: 2px solid $color-primary;
          border-right: 2px solid $color-primary;
        }
      }
      &:nth-child(2) {
        @media screen and (orientation: landscape) {
          border-inline: none;
        }
      }
      &:last-child {
        @media screen and (orientation: portrait) {
          border-bottom: 2px solid $color-primary;
          border-left: 2px solid $color-primary;
          border-right: 2px solid $color-primary;
        }
      }

      &.has-image {
        padding: 0; // Mantieni senza modifiche
      }
    }

    .title-wrap {
      height: 100%; // Mantieni senza modifiche
      position: relative;

      .title {
        font-weight: 500;
        margin: 0;
        white-space: nowrap;
        text-transform: none;
        font-family: inherit;
        @media screen and (orientation: landscape) {
          transform: rotateZ(-90deg);
          position: absolute;
        }
      }

      .content-text {
        font-family: 'articulat-cf', sans-serif;
        font-weight: 300;
      }
    }
  }

  .has-image {
    position: relative;

    img:not(.base-content-image):not(.content-image) {
      max-height: 656px; // Mantieni senza modifiche
    }
  }

  .overlay-content-wrap {
    position: absolute;
    @include rfs(top, 0); // Convertito top in px
    @include rfs(left, 0); // Convertito left in px
    height: 100%; // Mantieni senza modifiche
    width: 100%; // Mantieni senza modifiche
    background-color: white;
    opacity: 0;
    transition: opacity $fade-animation-speed ease-in-out;
    z-index: 3;
    pointer-events: none;
    overflow: auto;

    &.active {
      opacity: 1;
      pointer-events: auto;
    }
  }

  .text-content-col {
    overflow: auto;
    max-height: 658px; // Mantieni senza modifiche
  }

  .content-text {
    padding: 1.75rem; // Mantieni senza modifiche
  }

  .overlay-content-title-wrap {
    border-bottom: 2px solid $color-primary;
    text-align: center;
    // padding: calc(($bs-gutter-x) * 0.5) - 2.5px; // Commentato

    .title-overlay {
      font-family: 'articulat-cf', sans-serif;
      font-weight: 500;
      font-size: 2.625rem; // Mantieni senza modifiche
      line-height: 1.2;
      margin: 0;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}
