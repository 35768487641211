.c {
  &-lime {
    color: $color-accent;
  }
  &-white {
    color: $white;
  }
}

.stroke {
  &-white {
    -webkit-text-stroke: 1px $white;
    color: transparent;
  }
}
