.single-project-hero {
  height: 100vh;
  height: 100svh;
  position: relative;
  @media (orientation: portrait) {
    box-sizing: border-box;
  }
  .single-project-hero-inner {
    @include rfs(padding-bottom, $menuItemDistanceV); // Convertito 9rem in px
    @include rfs(padding-left, $menuItemDistanceH); // Convertito 18rem in px
    // @include rfs(padding-right, 18); // Convertito 18rem in px
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    box-sizing: border-box;
    @media (orientation: portrait) {
      box-sizing: border-box;
    }
    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%; // Mantieni senza modifiche
      height: 100%; // Mantieni senza modifiche
      object-fit: cover;
    }

    h2 {
      color: $color-accent;
      position: relative;
      text-transform: uppercase;
    }
  }
}

#works-images {
  flex-direction: column;
  gap: 0;
  // scroll-snap-type: y mandatory;
  // overflow-y: scroll;
  // height: 100vh;
  // height: 100svh;
  // scroll-behavior: smooth;

  // .single-project-hero {
  //   scroll-snap-align: start;
  // }
}
