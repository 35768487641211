$white: #ffffff;
$color-primary: #000000;
$color-accent: #e2e257;
$color-azure: #579197;
$color-accent-active: #eae7b2;
$color-accent-hover: #f2f1d9;
$default-font-size: 18;
$page-animation-speed: 0.6s;
$box-width-animation-speed: 0.4s;
$fade-animation-speed: 0.3s;
$dropdown-animation-speed: 0.2s;
$container-narrow-extra-margin: 216px;
$container-wide-extra-width: /* 280px; */ 344px;
