* {
  margin: 0;
  padding: 0;
}

.App {
  &.full-height-app {
    height: 100vh !important;
    height: 100svh !important;
    overflow: hidden !important;
    position: relative !important;
  }
}

body {
  font-family: 'articulat-cf', sans-serif;
  color: $color-primary;
}

.homepage-bg {
  height: /* 100% */ 100vh;
  width: 100%;
  object-fit: cover;
}

.fp-watermark {
  display: none !important;
}

.site-logo {
  @include rfs(max-height, 151); // Converte max-height in rfs
}

.pl-3x {
  // padding-left: calc($bs-gutter-x * 3);
}

.pr-3x {
  // padding-right: calc($bs-gutter-x * 3);
}

.error-text {
  @include rfs(font-size, $default-font-size);
  line-height: 1.2;
  @include rfs(padding, $default-font-size);
  margin-bottom: 0;
}

.overlay-trail {
  height: 100vh;
  background-color: transparent;
}

.diagonal-down {
  transform: rotate(45deg);
}

.diagonal-up {
  transform: rotateZ(-45deg);
}

img {
  max-width: 100%;
  height: auto;

  &.width-auto {
    width: auto;
  }
}

// @media (min-width: 768px) {
//   .container-narrow {
//     max-width: calc(720px - $container-narrow-extra-margin);
//   }

//   .container-wide {
//     max-width: calc(720px + $container-wide-extra-width);
//   }
// }

// @media (min-width: 992px) {
//   .container-narrow {
//     max-width: calc(960px - $container-narrow-extra-margin);
//   }

//   .container-wide {
//     max-width: calc(960px + $container-wide-extra-width);
//   }
// }

// @media (min-width: 1200px) {
//   .container-narrow {
//     max-width: calc(1140px - $container-narrow-extra-margin);
//   }

//   .container-wide {
//     max-width: calc(1140px + $container-wide-extra-width);
//   }
// }

// @media (min-width: 1400px) {
//   .container-narrow {
//     max-width: calc(1320px - $container-narrow-extra-margin);
//   }

//   .container-wide {
//     max-width: calc(1320px + $container-wide-extra-width);
//   }

//   .container-wide-narrow {
//   }
// }

// @media (min-width: 1024px) {
//   .tablet,
//   .mobile {
//     display: none;
//   }
// }

// @media (max-width: 1023px) {
//   .desktop,
//   .mobile {
//     display: none;
//   }
// }

// @media (max-width: 767px) {
//   .desktop,
//   .tablet {
//     display: none;
//   }
// }

/* END: CUSTOM */
