.rfm-marquee {
  height: rvh(80);
  @media screen and (orientation: portrait) {
    height: 5vh;
  }
  .rfm-child {
    // &:first-child {
    //   width: rvh(750);
    // }
    // &:nth-child(2) {
    //   width: rvh(550);
    // }
    // &:nth-child(3) {
    //   width: rvh(550);
    // }
    // @extend .t-50;
    // a {
    //   font-size: inherit;
    //   line-height: inherit;
    //   color: inherit;
    //   text-decoration: none;
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   svg {
    //     margin: 0 rvh(50);
    //     @media screen and (orientation: portrait) {
    //       height: 3vh;
    //       width: auto;
    //     }
    //   }
    // }
  }
}

.marquee {
  &__icons {
    display: flex;
    align-items: center;
  }
  &__single {
    display: flex;
    align-items: center;
    gap: 0 5px;
    margin: 0 rvh(50);
    img {
      width: auto;
      height: rvh(55);
    }
  }
  &__singleText {
    display: flex;
    align-items: center;
    gap: 0 10px;
  }
  &__tit {
    width: rvh(80);
    text-align: right;
  }
  &__text {
    // max-width: rvw(120);
    margin-left: 10px;
    line-height: 1;
  }
}
