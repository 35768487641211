// @font-face {
//   font-family: 'Acidic';
//   src:
//     url(/Acidic.woff) format('woff2'),
//     url(/Acidic.woff) format('woff');
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Acidic';
//   src:
//     local('Acidic'),
//     url('./fonts/Acidic.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }

@font-face {
  font-family: 'Acidic';
  src:
    local('Acidic'),
    url('./../fonts/Acidic.woff') format('woff'),
    url('./../fonts/Acidic.woff2') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
