.exhibitions {
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  @include rfs(padding-block-start, 1.75); // Convertito il padding-block
  @include rfs(padding-block-end, 1.75);
  height: 100%;
}

.exhibition {
  display: flex;
  align-items: center;
  @include rfs(gap, 20);
  &.is-past {
    filter: grayscale(1);
  }
  img {
    @include rfs(max-width, 120); // Convertito max-width senza px
    @media (orientation: portrait) {
      max-width: rvh(200);
    }
  }

  .date,
  .name,
  .author {
    font-family: inherit;
    margin: 0;
  }

  .name {
    text-transform: uppercase;
    font-weight: 500;
  }
}
