#benefit {
  .benefit-2050 {
    &__fixedBar {
      // position: fixed;
      // left: 0;
      // top: 0;
      // width: 100vw;
      // z-index: 2;
    }
    &__fixedMarquees {
      // position: fixed;
      // top: rvh(115);
      z-index: 5;
    }
    &__fixedMarqueesInner {
      display: flex;
      flex-direction: column;
    }
    .bg-senape {
      background-color: $color-accent-active;
      height: rvh(115);
      width: 100vw;
      @media screen and (orientation: portrait) {
        height: 7.5vh;
      }
    }
    &__marqWrapper {
      background-color: $color-accent-active;
      width: 100vw;
    }
    &__marqWrapperInner {
      display: flex;
      align-items: center;
    }
    &__marqWrapperLabel {
      width: 7.5%;
      @media (orientation: portrait) {
        width: 20%;
      }
    }
    &__marqWrapperMarquee {
      width: 92.5%;
      @media (orientation: portrait) {
        width: 80%;
      }
    }
    &__marqWrapperMarquee {
      border-top: 1px solid $color-primary;
    }
    &__cards {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: rvh(200) 0;
      gap: rvh(100) 0;
      @media (orientation: portrait) {
        padding: rvh(50) 0;
      }
      &::after {
        content: '';
        flex: 0 0 50%;
      }
      .card {
        @media (orientation: landscape) {
          &:nth-child(4n + 1),
          &:nth-child(4n + 2) {
            transform: translateX(rvw(50));
            &:nth-child(even) {
              transform: translateX(rvw(50)) translateY(rvh(-100));
            }
          }
        }
        &:nth-child(even) {
          @media (orientation: landscape) {
            transform: translateY(rvh(-100));
          }
          .card__wrapper {
            display: flex;
            justify-content: flex-end;
          }
        }
      }
    }

    // .benefit-2050-swiper {
    //   height: calc(100svh - rvh(115) - rvh(80));
    //   @media screen and (orientation: portrait) {
    //     height: calc(100svh - 7.5vh - 5vh);
    //   }
    //   .swiper-wrapper {
    //     @media (orientation: portrait) {
    //       padding: 0;
    //     }
    //     .benefit-slide-content {
    //       position: relative;
    //       display: flex;
    //       align-items: center;
    //       justify-content: center;
    //       height: 100%;
    //       width: 100%;
    //       &::after {
    //         content: '';
    //         position: absolute;
    //         left: 0;
    //         top: 0;
    //         width: 100%;
    //         height: 100%;
    //         z-index: 0;
    //         background-color: rgba(0, 0, 0, 0.2);
    //       }
    //       img {
    //         position: absolute;
    //         left: 0;
    //         top: 0;
    //         width: 100%;
    //         height: 100%;
    //         object-fit: cover;
    //         z-index: -1;
    //       }
    //       p {
    //         position: relative;
    //         z-index: 1;
    //       }
    //     }
    //   }
    // }
  }
}
