.goal-item {
  display: flex;
  @include rfs(column-gap, 3.75); // Convertito column-gap senza px
  border-bottom: 2px solid #289b97;
  align-items: flex-start;
  text-align: left;
  @include rfs(padding-top, 20); // Convertito margin-top senza px
  @include rfs(padding-bottom, 20); // Convertito margin-top senza px

  @media (orientation: portrait) {
    flex-direction: column;
  }
  .index {
    font-weight: 500;
    color: #289b97;
    @include rfs(min-width, 80); // Convertito min-width senza px
  }

  .text {
  }
}

.goals-list {
  @media (orientation: portrait) {
    margin-top: rvh(100);
  }
  .title-inner {
    text-align: left;
    @include rfs(padding-left, 80); // Convertito padding-left senza px
    @include rfs(margin-bottom, 50); // Convertito margin-bottom senza px

    .subtitle {
      @extend .t-50;
    }
  }
}
