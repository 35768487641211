.swiper-height-100 {
  height: 100%;
}

.button-arrow {
  padding: 0;
  border: 0;
  background: none;
  display: flex;
  cursor: pointer;
}

.swiper-button-disabled {
  opacity: 0;
}

.swiper-wrapper {
  align-items: center;
  @media (orientation: portrait) {
    padding: 20px 0px;
  }
}

.swiper.works-swiper {
  @include rfs(padding-block, 45); // Convertito 56px in px
}

.salotto-two-cols-section-swiper {
  .swiper-pagination-navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    text-align: center;
    transform: translate3d(0, 0, 0);
    z-index: 10;
    bottom: 0;
    top: auto;
    left: 0;
    width: 100%;
    padding: 20px;
    box-sizing: border-box;

    .button-arrow {
      &.swiper-nav-prev {
        visibility: hidden;
      }
      svg {
        width: 50px;
        height: auto;
        transform: rotate(-45deg);
        path {
          fill: $white;
        }
      }
    }
  }
}

.salotto-section,
.works-swiper {
  .swiper-pagination-navigation,
  .exhibitions-swiper .swiper-pagination-navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    text-align: center;
    transform: translate3d(0, 0, 0);
    z-index: 10;
    bottom: 8px;
    top: auto;
    left: 0;
    width: 100%;
  }

  .swiper-pagination-navigation {
    z-index: -1;
  }

  .swiper-pagination-bullets.swiper-pagination-horizontal,
  .exhibitions-swiper .swiper-pagination-bullets.swiper-pagination-horizontal {
    position: initial;
    top: initial;
    bottom: initial;
    z-index: initial;
    width: initial;
  }

  .swiper-button-prev,
  .swiper-button-next {
    background-color: #000; /* Colore di sfondo delle frecce */
    @include rfs(padding, 10); // Convertito 10px in px
    border-radius: 50%;
    color: #fff;
  }

  .swiper-pagination-bullet,
  .exhibitions-swiper .swiper-pagination-bullet {
    font-family: 'articulat-cf', sans-serif;
    font-weight: 500;
    width: 24px;
    height: 24px;
    text-align: center;
    line-height: 24px;
    @include rfs(font-size, 20); // Convertito 20px in px
    opacity: 1;
    background: transparent;
    color: #fff;
    text-shadow:
      0 0 1px black,
      0 0 1px black,
      0 0 1px black,
      0 0 1px black;
  }

  .swiper-pagination-bullet-active,
  .exhibitions-swiper .swiper-pagination-bullet-active {
    color: $color-primary;
    text-decoration-line: underline;
    text-shadow:
      0 0 1px white,
      0 0 1px white,
      0 0 1px white,
      0 0 1px white;
  }
}

.flex-center {
  display: flex;
  align-items: center;
}

.dir-column {
  flex-direction: row;
}

.link-btn {
  border: none;
  background: none;
  padding: 0;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    background-color: $color-accent-active;
    z-index: -1;
    transition: width $box-width-animation-speed ease-in-out;
  }

  &:hover:after {
    width: 100%;
  }
}

.link-btn,
a.hover-box,
.text-hover-box,
.works-swiper .work .work-title p {
  position: relative;
  text-decoration-line: none;
  color: $color-primary;
  width: fit-content;
  z-index: 1;

  &.hover-box:after,
  &.text-hover-box:after,
  .works-swiper .work .work-title p:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    background-color: $color-accent-active;
    z-index: -1;
    transition: width $box-width-animation-speed ease-in-out;
  }

  .works-swiper .work .work-title p {
    width: 75.5%;
    margin-bottom: 0;
  }

  &:hover {
    cursor: pointer;
  }
}
