.text-image-two-columns {
  display: flex;
  align-items: center;
  @media (orientation: portrait) {
    padding: 30px 0;
  }
  .col {
    &[class^='t-'] {
      p {
        font-size: inherit;
        line-height: inherit;
      }
    }
  }

  img {
    @include rfs(width, 680);
    display: flex;
    @media (orientation: portrait) {
      width: 100%;
    }
  }

  .svg-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    > svg {
      @include rfs(width, 264);
      @media (orientation: portrait) {
        width: 50%;
        margin: 0 auto;
      }
    }

    > svg #logo-animate-group {
      transform-origin: center;
      animation: logo-spin 20s forwards infinite linear;
    }
  }
}
