.fullscreen-image {
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &.is-full-height {
    img {
      height: 100vh;
    }
  }
}
