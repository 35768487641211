$menuItemDistanceH: 270;
$menuItemDistanceV: 30;
$menuItemDistanceHportrait: 5vw;
$menuItemDistanceVportrait: 5vw;
.menu-item {
  position: relative;
  max-width: fit-content; // Mantieni senza modifiche
  position: absolute;
  white-space: pre-wrap;
  text-transform: uppercase;
  cursor: pointer;
  pointer-events: auto;
  transition: $transition;
  z-index: 15;

  &::after {
    content: '';
    background-color: $color-accent-active;
    width: 0;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    transition: width $box-width-animation-speed ease-in-out;
    height: 100%;
  }

  a {
    color: $color-primary;
    text-decoration-line: none;
  }

  &.top {
    @include rfs(top, $menuItemDistanceV);
    @include rfs(left, $menuItemDistanceH);
    &.not-current:not(.hovered) {
      top: 0;
      transform: translateY(-50%);
    }

    @media (orientation: portrait) {
      left: $menuItemDistanceHportrait;
    }
  }

  &.right {
    @include rfs(top, 300);
    @include rfs(right, 80);
    transform: translateX(90%) translateY(-100%) rotate(90deg);
    transform-origin: bottom left;
    &.not-current:not(.hovered) {
      transform: translateX(112%) translateY(-100%) rotate(90deg);
      @media (orientation: portrait) {
        transform: translateX(103%) translateY(-100%) rotate(90deg);
      }
    }
    @media (orientation: portrait) {
      right: $menuItemDistanceHportrait;
      top: 19vh;
    }
  }

  &.left {
    @include rfs(bottom, $menuItemDistanceV);
    @include rfs(left, 80);
    transform: rotateZ(-90deg) translateY(100%);
    transform-origin: bottom left;
    &.not-current:not(.hovered) {
      left: 0;
      transform: rotateZ(-90deg) translateY(50%);
    }
    @media (orientation: portrait) {
      left: $menuItemDistanceHportrait;
      @include rfs(bottom, 210);
    }
  }

  &.bottom {
    @include rfs(bottom, $menuItemDistanceV);
    @include rfs(right, $menuItemDistanceH);
    &.not-current:not(.hovered) {
      bottom: 0;
      transform: translateY(50%);
    }
    @media (orientation: portrait) {
      right: $menuItemDistanceHportrait;
      bottom: $menuItemDistanceVportrait;
    }
  }

  &:hover::after,
  &.current-menu-item::after {
    width: 100%; // Lasciato come è, se vuoi cambiare a 190% modifica qui
  }
}
