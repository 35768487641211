.collab-section {
  @media (orientation: portrait) {
    margin-top: 10vh;
  }
  //   .col-title {
  //     font-family: 'articulat-cf', sans-serif;
  //     @include rfs(font-size, 2.5); // Convertito 2.5rem in px
  //     line-height: 0.875;
  //     font-weight: 500;
  //   }

  //   .col-text {
  //     font-family: 'articulat-cf', sans-serif;
  //     @include rfs(font-size, 1.625); // Convertito 1.625rem in px
  //     line-height: 2.1875rem;
  //     font-weight: 300;

  //     &.flex {
  //       display: flex;
  //       flex-direction: column;
  //       align-items: flex-end;
  //     }
  //   }
  .col {
    @media (orientation: portrait) {
      flex-basis: 100% !important;
      flex-shrink: unset !important;
    }
  }
  &__text {
    max-width: 70%;
    @media (orientation: portrait) {
      max-width: 100%;
    }
  }
  .col-text {
    * {
      text-align: right;
    }
  }
}
