// NVM USE 16
// Funzione per convertire misure da pixel a vw/vh
/* ARGOMENTI ->
	? $px-vw/$px-vh = misura in pixel voluta (senza unità di misura)
	? $multiplier = moltiplicatore utile per scalare diversamente le misure nel caso di diverse misure dello schero, di default 1
	? $min = misura minima, il valore ritornato non scalerà meno della misura inserita (in pixel), di default non c'è nessuna misura minima
	? $min = misura massima, il valore ritornato non scalerà più della misura inserita (in pixel), di default non c'è nessuna misura massima
*/
/* USO ->
	! .elemento {
	!  width: rvw(200, 1.5, 180, 220);
	!  height: rvh(150, 1.5, 130, 170);
	! }
*/

@function rvw($px-vw, $multiplier: 1, $min: null, $max: null) {
  $base-vw: 1920px;
  $px: $px-vw * $multiplier * 1px;
  $val: calc($px * 100vw / $base-vw);

  @return $val;

  $min-val: $val;

  @if ($min) {
    $min-val: $min * $multiplier * 1px;
  }

  $max-val: $val;

  @if ($max) {
    $max-val: $max * $multiplier * 1px;
  }

  @return clamp($min-val, $val, $max-val);
}

@function rvh($px-vh, $multiplier: 1, $min: null, $max: null) {
  $base-vh: 1080px;
  $px: $px-vh * $multiplier * 1px;
  $val: calc($px * 100vh / $base-vh);

  $min-val: $val;

  @if $min {
    $min-val: $min * $multiplier * 1px;
  }

  $max-val: $val;

  @if $max {
    $max-val: $max * $multiplier * 1px;
  }

  @return clamp($min-val, $val, $max-val);
}

@function scale-font-size($size) {
  $scale: 1;
  @if ($size <= 30) {
    $scale: 0.9;
  }
  @if ($size >= 30) {
    $scale: 0.8;
  }
  @if ($size >= 45) {
    $scale: 0.7;
  }
  @if ($size >= 60) {
    $scale: 0.7;
  }
  @if ($size >= 80) {
    $scale: 0.7;
  }
  @if ($size >= 100) {
    $scale: 0.6;
  }
  @if ($size >= 200) {
    $scale: 0.3;
  }
  @if ($size >= 300) {
    $scale: 0.2;
  }

  @return $scale;
}

@mixin rfs($prop, $size) {
  & {
    #{$prop}: rvw($size);
  }
  & {
    @media (orientation: portrait) {
      $scale: scale-font-size($size);
      #{$prop}: rvh($size * $scale);
    }
  }
}

@mixin printPageSpace() {
  & {
    @include rfs(padding-top, 240);
  }
  & {
    @media (orientation: portrait) {
      padding-top: rvh(150);
    }
  }
}
