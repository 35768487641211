#back-to-home {
  position: fixed;
  display: none;
  cursor: pointer;
  mix-blend-mode: difference;
  @include rfs(right, 80);
  @include rfs(bottom, $menuItemDistanceV);
  transform: translateX(100%) translateY(-50%) rotate(-90deg);
  transform-origin: bottom left;
  z-index: 20;
  transition: $transition;
  @media (orientation: portrait) {
    right: $menuItemDistanceHportrait;
    @include rfs(bottom, 210);
  }
  &.visible {
    display: block;
  }
  &:hover {
    opacity: 0.75;
  }
  .back-to-home__label {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    @include rfs(gap, 20);
    color: $white;
    text-decoration: none;
    animation: moveUp 1s linear infinite alternate-reverse;
    svg {
      path {
        fill: $white;
      }
    }
  }
}
