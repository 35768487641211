.team-grid {
  text-align: center;

  .team-grid-inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @include rfs(gap, 60);
  }

  .team-grid-person {
    display: flex;
    flex-direction: column;
    @include rfs(width, 430);
    @media (orientation: portrait) {
      flex: 0 0 45%;
    }
    img {
      width: 100%;
    }

    .name {
      margin-top: 1.875rem; // Lasciato come rem
    }

    .job-title {
      font-style: oblique;
      text-align: center;
    }
  }
}
