.card {
  flex: 0 0 50%;
  text-decoration: none;
  @media (orientation: portrait) {
    flex: 0 0 100%;
  }
  &:hover {
    .card__wrapper {
      transform: scale(1.03);
    }
  }
  &.dotted,
  &.bordered {
    .card__image {
    }
  }
  &.bordered {
    .card__image {
      border: 3px solid $color-azure;
      @media (orientation: portrait) {
        // padding-bottom: 0;
      }
      img {
        height: auto;
      }
    }
  }
  &.dotted {
    .card__image {
      padding: rvh(20) rvw(40);
      padding: rvh(20) rvw(40);
      border: 3px solid $color-azure;
      background-color: white; /* Sfondo bianco */
      background-image: radial-gradient(
        $color-azure 10%,
        transparent 11%
      ); /* Pallini blu */
      background-size: 20px 20px; /* Distanza tra i pallini */
      @media (orientation: portrait) {
        box-sizing: border-box;
      }
    }
  }
  &.no-text-content {
    .card__title,
    .card__text {
      display: none;
    }
    .card__image {
      img {
        min-height: rvh(650);
        @media (orientation: portrait) {
          min-height: auto;
        }
      }
    }
  }
  &__wrapper {
    width: 100%;
    position: relative;
    color: $color-azure;
    transition: all 0.2s ease-in-out;
    transform-origin: center center;
  }
  &__cat {
    position: absolute;
    left: rvw(-5);
    bottom: 0;
    z-index: 1;
    color: inherit;
    transform-origin: bottom left;
    transform: rotate(-90deg);
    text-transform: uppercase;
    @media (orientation: portrait) {
      transform: translateX(-50%) translateY(100%);
      left: 50%;
      bottom: rvh(-10);
    }
  }
  &__image {
    position: relative;
    width: rvw(450);
    display: flex;
    flex-direction: column;
    min-height: rvh(650);
    justify-content: space-between;
    @media (orientation: portrait) {
      box-sizing: border-box;
    }
    @media (orientation: portrait) {
      width: 100%;
      min-height: auto;
    }
    img {
      height: rvh(500);
      width: 100%;
      object-fit: cover;
      @media (orientation: portrait) {
        height: 40vh;
      }
    }
  }
  &__title {
    margin: rvh(15) 0 rvh(5);
    line-height: rvh(42);
  }
}
