.page {
  position: absolute;
  left: 0;
  top: 100vh;
  opacity: 0;
  z-index: 10;
  width: 100vw;
  transition: $transition;
  height: inherit;
  overflow: auto;

  &-decor-top {
    // box-shadow: 0 -2px 15px 5px rgba(0, 0, 0, 0.5); // Commentato
  }

  &-decor-bottom {
    // box-shadow: 0 2px 15px 5px rgba(0, 0, 0, 0.5); // Commentato
  }

  .content {
    background-color: #fff;
  }

  &:not(.active) {
    pointer-events: none;
  }

  &.active {
    opacity: 1;
    top: 0vh;
    z-index: 11;
  }

  &.active.exit {
    opacity: 0;
    top: 100vh;
    // animation: page-slide-out;
    // animation-timing-function: ease-out;
    // animation-duration: $page-animation-speed;
    // animation-fill-mode: forwards;
    z-index: 10;
  }
}
