#benefit {
  .main {
    &__content {
      @include rfs(width, 1400);
      // @include rfs(padding-left, 70);
      @media screen and (orientation: portrait) {
        width: 80%;
        padding: 15vh 7.5vw;
        box-sizing: border-box;
      }
      .text-image-two-columns,
      .proposition-slider,
      .goals-list {
        @media screen and (orientation: portrait) {
          width: 100% !important;
        }
      }
      .row {
        @media screen and (orientation: portrait) {
          justify-content: flex-start;
        }
      }
    }
    &__fixed-colors {
      position: fixed;
      top: 0;
      right: 0;
      height: 100svh;
      @include rfs(width, 520);
      @media screen and (orientation: portrait) {
        width: 20%;
      }
      &__link {
        position: absolute;
        left: 0;
        bottom: 20%;
        text-decoration: none;
        color: inherit;
        white-space: nowrap;
        transform-origin: center center;
        transform: translateY(-50%) translateX(-58%) rotate(-90deg);
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
