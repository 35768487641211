.proposition-slider {
  position: relative;
  @include rfs(padding-bottom, 100); // Convertito top senza px
}

.proposition-slide {
  position: absolute;
  @include rfs(top, 0); // Convertito top senza px
  opacity: 0;
  z-index: -1;
  .col {
    max-width: 75%;
    @media screen and (orientation: portrait) {
      max-width: 100%;
    }
  }
  &.active {
    position: initial;
    opacity: 1;
  }
}

.proposition {
  font-style: italic;
}
