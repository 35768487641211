.dropdown {
  position: relative;
  border: 1px solid $color-primary;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all $dropdown-animation-speed ease-in-out;
  cursor: pointer;
  width: fit-content;
  z-index: 100;
  @include rfs(padding, 10);

  &.open,
  &.open.active {
    background-color: $color-primary;
    color: white;
  }

  &.open {
    & .dropdown-list {
      border-color: $color-primary;
      background-color: white;
      transform: scaleY(100%);
      pointer-events: auto;

      ul {
        li {
          opacity: 1;
        }
      }
    }
  }
  &.active {
    background-color: $color-accent-active;
    color: $color-primary;
  }

  &.open svg * {
    fill: white;
  }

  .dropdown-list {
    position: absolute;
    top: 100%;
    left: -1px;
    width: 100%;
    border: 1px solid transparent;
    background-color: transparent;
    transform-origin: top;
    transform: scaleY(0);
    transition: all $dropdown-animation-speed ease-in-out;
    pointer-events: none;

    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;

      li {
        opacity: 0;
        transition: all $dropdown-animation-speed ease-in-out;
        cursor: pointer;
        @include rfs(padding-block, 9);
        @include rfs(padding-inline, 12);

        &:not(.current-item) {
          color: $color-primary;
        }

        &.current-item {
          color: $color-primary;
          background-color: $color-accent-active;
        }

        &:not(.current-item):hover {
          color: $color-primary;
          background-color: $color-accent-hover;
        }
      }
    }
  }

  .dropdown-label {
    @include rfs(padding-right, 34); // Separato padding-right
  }

  .dropdown-arrow {
    transition: transform $fade-animation-speed ease-in-out;
  }
}
