#main-menu,
#main-menu ul {
  height: 100%;
}

#main-menu ul {
  position: relative;
  list-style-type: none;
  text-align: left;
}

#menu-overlay {
  z-index: 12;
  pointer-events: none;
}

#labels-overlay {
  z-index: 1;
}

.main-menu {
  &__sensibles {
    pointer-events: all;
    &--top,
    &--right,
    &--bottom,
    &--left {
      position: fixed;
      z-index: 10;
    }
    &--left,
    &--right {
      width: rvh(50);
      height: 100vh;
      bottom: 0;
    }
    &--top,
    &--bottom {
      width: 100vw;
      height: rvh(50);
      left: 0;
    }
    &--top {
      top: 0;
    }
    &--bottom {
      bottom: 0;
    }
    &--left {
      left: 0;
    }
    &--right {
      right: 0;
      @media screen and (orientation: landscape) {
        right: 30px; // avoid scrollbar overlap
      }
    }
  }
}
