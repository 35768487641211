h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: aptly, sans-serif;
  font-weight: bold;
  color: $color-primary;
}

h1 {
  @include rfs(font-size, 224);
}

table th,
li,
span {
  @extend .t-20;
  font-weight: initial;
}

table td,
a,
p {
  @extend .t-26;
  font-weight: 300;
  text-align: left;
  white-space: pre-wrap;
}
.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

.text-align-right {
  text-align: right;
}

.title {
  @extend .t-50;
  font-weight: 500;
  text-transform: uppercase;
}

.subtitle {
  @extend .t-42;
  font-weight: 600;
  font-family: 'articulat-cf', sans-serif;
}
.t-articulat {
  font-family: 'articulat-cf', sans-serif;
}
.t-acidic {
  font-family: 'Acidic';
}
.t-aptly {
  font-family: aptly, sans-serif;
}
.text-italic {
  font-style: italic;
}

.t-medium {
  font-weight: 500;
}

.t-bold {
  font-weight: 800;
}

// Array di font-size, line-height e letter-spacing
$array: (
  // font-size, line-height, letter-spacing
  (350, 350, -20),
  (170, 170, null),
  (130, 130, null),
  (100, 100, null),
  (80, 80, null),
  (72, 80, null),
  (50, 60, null),
  (42, 50, null),
  (35, 45, null),
  (26, 36, null),
  (20, 28, null),
  (14, 20, null)
);

// Loop per generare le classi
@each $pair in $array {
  $size: nth($pair, 1);
  $line-height: nth($pair, 2);
  $letter-spacing: nth($pair, 3);

  .t-#{$size} {
    font-size: rvw($size);
    line-height: rvw($line-height);

    // Condizione per letter-spacing
    @if $letter-spacing != null {
      letter-spacing: rvw($letter-spacing);
    }

    @media (orientation: portrait) {
      $scale: scale-font-size($size);
      font-size: rvh($size * $scale);
      line-height: rvh($line-height * $scale);

      // Condizione per letter-spacing in portrait
      @if $letter-spacing != null {
        letter-spacing: rvh($letter-spacing * $scale);
      }
    }
  }
}
