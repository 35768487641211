@keyframes logo-spin {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

@keyframes page-slide-in {
  0% {
    opacity: 0;
    transform: translateY(100vh);
  }
  1% {
    opacity: 1;
  }
  90% {
    /* transform: translateY(-1.5vh); */
  }
  100% {
    opacity: 1;
    transform: translateY(0vh);
  }
}

@keyframes page-slide-out {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  99% {
    opacity: 1;
    transform: translateY(-100vh);
  }
  100% {
    opacity: 0;
    transform: translateY(-100vh);
  }
}

@keyframes blink {
  0%,
  50% {
    opacity: 0;
  }
  51%,
  100% {
    opacity: 1;
  }
}

@keyframes moveUp {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(10px);
  }
}

@keyframes strokeFill {
  0% {
    fill: $white;
    stroke: transparent;
    stroke-width: 1px;
  }
  100% {
    fill: transparent;
    stroke: $white;
    stroke-width: 3px;
  }
}
