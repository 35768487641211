.benefit-view-2050 {
  position: fixed;
  z-index: 20;
  width: fit-content;
  display: none;
  align-items: center;
  justify-content: flex-end;
  @include rfs(top, $menuItemDistanceV); // Convertito 9rem in px
  @include rfs(right, $menuItemDistanceH); // Convertito 9rem in px

  @media screen and (orientation: portrait) {
    // top: 2.5vh;
    // right: unset;
    right: $menuItemDistanceHportrait;
    // justify-content: flex-start;
  }
  &.visible {
    display: flex;
  }

  .benefit-view-2050-inner {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @include rfs(gap, 30);

    p {
      cursor: pointer;
      transition: all $fade-animation-speed ease-in-out;
      -webkit-text-stroke: 1px transparent;

      &:not(.active) {
        color: transparent;
        -webkit-text-stroke: 1px black;
      }

      &:hover:not(.active) {
        opacity: 0.4;
      }
    }
  }
}
